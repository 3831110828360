import ApiUrl from "./api-url";
import axios, { isCancel } from 'axios';
import {ApiHeaderOptions, getAuthorizationToken } from "./api-header-options";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';


const register = async (data: any) => {
    const url = `${ApiUrl}/v1/core/users/`;
    return await axios.post(url, data).then((response) => {
        userSendActivation(data.email);
        return response;
    }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const userSendActivation = async (email: any) => {
    const url = `${ApiUrl}/v1/auth/users/resend_activation/`;
    const frontEndUrl = process.env.FRONTEND_URL || "go.lazem.sa" ;
    let data = {
        "email": email,
        "website_name": "Lazem GO",
        "website_url": frontEndUrl + "/activate/?email=" + email + "&&",
    }
    return await axios.post(url, data).then((response) => {
        return response;
    }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const activateAccount = async (data: any) => {
    const url = `${ApiUrl}/v1/auth/users/activation/`;
    return await axios.post(url, data).then((response) => {
        return response;
    }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

// const setLocalStorage = async (data: any) => {
//     let decoded = jwtDecode(data.access);
//     await localStorage.setItem('Authorization', "JWT " + data.access);
//     await localStorage.setItem('expiry', (decoded.exp || 0 * 1000).toString());
// }

// const setCookie = async (data: any) => {
//     const [cookies, setCookie] = useCookies(['Authorization']);
//     let decoded = jwtDecode(data.access);
//     setCookie('Authorization', "JWT " + data.access, {
//         expires: new Date(decoded.exp || 0)
//     })
// }


const userLogin = async (data: any) => {
    const url = `${ApiUrl}/v1/auth/jwt/create/`;
    return await axios.post(url, data).then(async (response) => {
        // await setCookie(response.data);
        return response;
    }).catch((error: any) => {
        console.log('Error', error);
        throw error;
    });
}

const resetPassword = async (data: any) => {
    const url = `${ApiUrl}/v1/auth/users/reset_password/`;
    return await axios.post(url, data).then((response) => {
        return response;
    }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const submitNewPassword = async (data: any) => {
    const url = `${ApiUrl}/v1/auth/users/reset_password_confirm/`;
    return await axios.post(url, data).then((response) => {
        return response;
    }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}


const getMyProfile = async () => {
    const url = `${ApiUrl}/v1/core/users/profile/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error: any) => {
        if (error?.response?.data?.code === 'token_not_valid') {
            console.log('Token not valid', error);
            users.refreshToken();
            throw error;
        }
        else {
            console.log('Form Error', error);
            throw error;
        }
    });
}

const getMyUser = async () => {
    const url = `${ApiUrl}/v1/core/users/me/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const updateProfile = async (data: any) => {
    const url = `${ApiUrl}/v1/core/profile/`;
    return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getAllUsers = async () => {
    const url = `${ApiUrl}/v1/core/users/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const handelSetCookie = async (data: any) => {
    let decoded = jwtDecode(data.access);
    Cookies.set('Authorization', "JWT " + data.access, {
        expires: new Date((decoded.exp || 0) * 1000)
    })
}

const users = {
    me: async () => {
        const url = `${ApiUrl}/v1/core/users/me/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    simple_me: async () => {
        const url = `${ApiUrl}/v1/core/users/simple_me/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    update_me: async (data: any) => {
        /*
        example_data = {
            "profile_user": {
                "phone_number": "05555555",
                "address": "Riyadh",
                "gender": "Male"
            },
            "staff_profile": {
                "department": "01fbb8d8-8ca6-4865-96d6-0fc52cfce224",
                "role": "ae3e28af-612e-490f-b565-45f07474d228"
            },
            "middle_name": "Bakheet",
            "last_name": "Bashah"
        }
        */
        const url = `${ApiUrl}/v1/core/users/me/`;
        return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    list: async () => {
        const url = `${ApiUrl}/v1/core/users/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/users/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    profiles: async () => {
        const url = `${ApiUrl}/v1/core/users/profiles/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    simple: async () => {
        // Get simple user data [id, full_name, email]
        // This is useful for getting a list of users in a dropdown/select input field
        const url = `${ApiUrl}/v1/core/users/simple/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    update: async (id: any, data: any) => {
        /*
        example_data = {
            "profile_user": {
                "phone_number": "05555555",
                "address": "Riyadh",
                "gender": "Male"
            },
            "staff_profile": {
                "department": "01fbb8d8-8ca6-4865-96d6-0fc52cfce224",
                "role": "ae3e28af-612e-490f-b565-45f07474d228"
            },
            "middle_name": "Bakheet",
            "last_name": "Bashah"
        }
        */
        const url = `${ApiUrl}/v1/core/users/${id}/`;
        return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    refreshToken : async () => {
        const url = `${ApiUrl}/v1/auth/jwt/refresh/`;
        let token = getAuthorizationToken();
        let refresh = token.split(' ')[1];
        let data = {
            "refresh": refresh
        }
        return await axios.post(url, data, ApiHeaderOptions()).then(async (response) => {
            await handelSetCookie(response.data);
            return response;
        }).catch((error: any) => {
            console.log('Refresh Token Error', error);
            throw error;
        });
    },
    verifyToken : async () => {
        const url = `${ApiUrl}/v1/auth/jwt/verify/`;
        return await axios.post(url, {
            token: getAuthorizationToken()
        }).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    }
}
const roles = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/roles/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/roles/${id}/`;
        return await axios.get(url, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/core/roles/`;
        return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/core/roles/${id}/`;
        return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
        });
    },
}
export { register, userLogin, resetPassword, submitNewPassword, userSendActivation, activateAccount, getMyProfile, updateProfile, getMyUser, getAllUsers, users, roles };