import { useLanguage } from "@/components/context/LanguageContext";
import ErrorCard from "@/components/error-card";
import LoadingScreen from "@/components/loading/loading-screen";
import { PermissionGroups } from "@/services/permission-group-services";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
  Card,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const PermissionGroupDashboard = () => {
  const {language} = useLanguage();
  const navigate = useNavigate();
  const [sysetemError, setSystemError] = useState('');

  const { data:permissionGroupData, isLoading:permissionGroupLoading } = useQuery({
    queryKey: ["permissionGroupList"],
    queryFn: ()=>PermissionGroups.list().catch((error) => {
      setSystemError(error.message)
      console.error("Errorrrr",error)
    }),
    refetchOnWindowFocus: false
    });

    useEffect(() => {
      document.title = "Lazem GO - Permission Groups";
    } , []);

  return (
    <div
      dir={language === "en" ? "ltr" : "rtl"}
      className="w-full p-4"
    >
      {permissionGroupLoading && <LoadingScreen />}
      {sysetemError && 
        <ErrorCard 
          error={sysetemError} 
          onClick = {()=>setSystemError('')}
          />
      }
        <h1
          className="text-2xl font-semibold mt-4"
        >
          {language === "en" ? "Permission Management" : "إدارة الصلاحيات"}        
        </h1>
        
        <Button
          className="mt-4 flex mx-6"
          onClick={()=>navigate('/permission-group/create')}
        >
          {language === "en" ? "Create Permission Group" : "إنشاء مجموعة صلاحيات"}
        </Button>

        <div
          className="mt-4 flex flex-wrap gap-4 justify-content-center items-center w-fit mx-auto p-4"
        >
          {permissionGroupData && permissionGroupData?.map((permissionGroup: any) => (
            <Card 
              key={permissionGroup.id}
              className="w-64 h-64 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
            >
              <CardHeader
                className="h-1/3 w-full flex justify-content-center m-auto"
                onClick={() => navigate(`/permission-group/id/${permissionGroup.id}`)}
              >
                <CardTitle
                  className="text-2xl font-bold flex justify-content-center m-auto"
                >
                  {permissionGroup.name}
                </CardTitle>
              </CardHeader>
            </Card>
          )
          )}
        </div>
    </div>
  );
};

export default PermissionGroupDashboard;