import ApiUrl from "./api-url";
import axios, {isCancel} from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { users } from "./user-service";

const getVehicles = async () => {
    const url = `${ApiUrl}/v1/fleet/vehicles/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getVehicle = async (id: number) => {
    const url = `${ApiUrl}/v1/fleet/vehicles/${id}/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const addVehicle = async (data: any) => {
    const url = `${ApiUrl}/v1/fleet/vehicles/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const updateVehicle = async (id: number, data: any) => {
    const url = `${ApiUrl}/v1/fleet/vehicles/${id}/`;
    return await axios.put(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const createVehicleStatus = async (data: any) => {
    const url = `${ApiUrl}/v1/fleet/status/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getVehicleStatus = async () => {
    const url = `${ApiUrl}/v1/fleet/status/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

export {getVehicles, getVehicle, addVehicle, updateVehicle, createVehicleStatus, getVehicleStatus};