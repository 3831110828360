import { useLanguage } from "@/components/context/LanguageContext";
import { 
    Card,
    CardContent,
    CardHeader,
    CardTitle,
 } from "@/components/ui/card";
 import { Button } from "@/components/ui/button";
 import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import ErrorCard from "@/components/error-card";
import { Label } from "@/components/ui/label";
import { getVehicles } from "@/services/fleet-services";
import { useQuery } from "@tanstack/react-query";

const FleetDashboard = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const [fleets, setFleets] = useState<any>();
    const [systemError, setSystemError] = useState<string>('');

    useEffect(() => {
        document.title = 'Lazem GO - Fleets';
    } , []);

    const fetchVehicles = async () => {
        if (!fleets){
            await getVehicles().then((response: any) => {
                // console.log('Vehicles', response);
                setFleets(response.data);
            }).catch((error: any) => {
                console.log('Error', error);
                setSystemError(error.message);
            })
        }
    }

    const {isLoading: vehiclesLoading} = useQuery({
        queryKey: ['vehicles'],
        queryFn: fetchVehicles,
    });

      return (
        <>
            {vehiclesLoading === true  && systemError === '' && <LoadingScreen />}
            {systemError !== '' && <ErrorCard error={systemError} onClick={() => setSystemError('')} />}
            {
                fleets &&
                    <div
                        dir={language === "en" ? "ltr" : "rtl"}
                    >
                        <div
                            className=""
                        >
                            <h1
                                className=" text-2xl font-bold"
                            >
                                {language === "en" ? "Fleets" : "الأسطول"}
                            </h1>
                            <div
                                className="flex justify-end mb-4"
                            >
                                <Button
                                    onClick={() => navigate('/fleet/add-vehicle')}
                                    className="my-4"
                                >
                                    {language === "en" ? "Add Vehicle" : "إضافة مركبة"}
                                </Button>
                            </div>
                        </div>
                        <div 
                            className="gap-4 flex flex-wrap justify-center items-center"
                        >
                            {fleets.map((vehicle: any) => (
                                <>
                                    {
                                        vehicle.is_active && !vehicle.is_archived &&(
                                            <Card
                                                key={vehicle.id}
                                                className="w-64 text-start cursor-pointer"
                                                onClick={() => navigate(`/fleet/id/${vehicle.id}`)}
                                            >
                                                <CardHeader>
                                                    {/* <Label
                                                        className="text-start"
                                                    >
                                                        {vehicle?.status[0]?.title || "Status"}
                                                    </Label> */}
                                                    <CardTitle
                                                        className="text-center"
                                                    >
                                                        {vehicle.code}
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardContent>
                                                    <div
                                                        className=""
                                                    >
                                                        <Label>
                                                            {language === "en" ? "Description" : "الوصف"}
                                                        </Label>
                                                        <p>{vehicle.description}</p>
                                                    </div>
                                                    <div
                                                        className="my-4"
                                                    >
                                                        <p>
                                                            {vehicle.brand} - {vehicle.manufacturing_year.substring(0, 4)}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="my-4"
                                                    >
                                                        <Label>
                                                            {language === "en" ? "Plate Number" : "رقم اللوحة"}
                                                        </Label>
                                                        <p>{vehicle.plate_number}</p>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        )
                                    }
                                </>
                            ))}
                        </div>
                    </div>
            }
        </>
      );
    };
    
    export default FleetDashboard;