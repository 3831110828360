import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ErrorCard from "@/components/error-card";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { createVehicleStatus } from "@/services/fleet-services";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"

const CreateVehicleStatus = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const [status, setStatus] = useState<any>({
        title: '',
        description: '',
        is_available : true,
        is_maintenance : false,
    });
    const [systemError, setSystemError] = useState('');
    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState('');

    useEffect(() => {
        document.title = 'Lazem GO - Create Vehicle Status';
    } , []);

    const handleCreate = async () => {
        setFormLoading(true);
        setFormError('');
        if (checkForm()) {
            await createVehicleStatus(status).then(() => {
                navigate('/fleet');
            }).catch((error) => {
                console.log(error);
                setSystemError(language === 'en' ? 'Something went wrong' : 'حدث خطأ ما');
            }).finally(() => {
                setFormLoading(false);
            });
        }

    };

    const checkForm = () => {
        if (status.title === '') {
            setFormError(language === 'en' ? 'Title is required' : 'العنوان مطلوب');
            return false;
        }
        if (status.description === '') {
            setFormError(language === 'en' ? 'Description is required' : 'الوصف مطلوب');
            return false;
        }
        return true;
    };

    return (
        <div 
            className="flex flex-col items-center justify-center h-full"
            dir={language === 'en' ? 'ltr' : 'rtl'}    
        >   
            {
                systemError !== '' && <ErrorCard error={systemError} onClick={() => setSystemError('')} />
            }
            {(                    
                    <Card className="w-full md:w-1/2">
                        <CardHeader>
                            <CardTitle>
                                {language === 'en' ? 'Create Status' : 'إنشاء حالة'}
                            </CardTitle>
                        </CardHeader>
                        <CardContent
                            className="text-start"
                        >
                            <div className="">
                                <div
                                    className="mb-4"
                                >
                                    <Label
                                        className="mb-4"
                                    >
                                        {language === 'en' ? 'Title' : 'العنوان'}
                                    </Label>
                                    <Input
                                        value={status.title}
                                        onChange={(e) => {
                                            setStatus({
                                                ...status,
                                                title: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div
                                    className="mb-4"
                                >
                                    <Label
                                        className="mb-4"
                                    >
                                        {language === 'en' ? 'Description' : 'الوصف'}
                                    </Label>
                                    <Input
                                        value={status.description}
                                        onChange={(e) => {
                                            setStatus({
                                                ...status,
                                                description: e.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div
                                    className="my-4 flex flex-col space-y-4"
                                >
                                    <RadioGroup
                                    onValueChange = {(value) => {
                                        setStatus({
                                            ...status,
                                            is_available: value === 'available' ? true : false,
                                            is_maintenance: value === 'maintenance' ? true : false,
                                        });
                                    }}
                                    className="flex flex-col space-y-4 justify-start text-start "
                                >

                                            <div
                                                className={`flex space-x-2 ${language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}
                                            >
                                                <RadioGroupItem 
                                                    value={"available"} 
                                                    id={"available"}
                                                    className="mx-2"
                                                />
                                                <Label htmlFor={"available"}>
                                                    {language === 'en' ? 'Available' : 'متاح'}
                                                </Label>
                                            </div>

                                            <div
                                                className={`flex space-x-2 ${language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}
                                            >
                                                <RadioGroupItem 
                                                    value={"maintenance"} 
                                                    id={"maintenance"}
                                                    className="mx-2"
                                                />
                                                <Label htmlFor={"maintenance"}>
                                                    {language === 'en' ? 'Maintenance' : 'صيانة'}
                                                </Label>
                                            </div>
                                </RadioGroup>
                                </div>
                                {formError !== '' ?
                                    <div className="mb-4 text-red-500">
                                        {formError}
                                    </div>
                                    : null
                                }
                                <div className="flex justify-end">
                                    <Button
                                        onClick={handleCreate}
                                        disabled={formLoading}
                                        className="w-full "
                                    >
                                        {formLoading ?
                                            <>
                                                <LoadingIcon />
                                            </>
                                            :
                                            language === 'en' ? 'Create Status' : 'إنشاء حالة'
                                        }    
                                    
                                    </Button>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                )
        }
            
        </div>
    );
};

export default CreateVehicleStatus;