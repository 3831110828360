import './App.css';
import { useAuth } from './components/context/AuthContext';
import LoadingScreen from './components/loading/loading-screen';
import SideNavigation from './components/side-bar';
import { Outlet } from 'react-router-dom';

function App() {
  const { isAuthenticated, isPermissionsLoading } = useAuth();

  // If the user is authenticated but permissions are still loading, show the loading screen
  if (isAuthenticated && isPermissionsLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="w-screen">
      {isAuthenticated && (
        <div className="">
          <SideNavigation />
        </div>
      )}
      <div className={`flex-grow overflow-hidden ${isAuthenticated ? 'lg:ml-[15rem]' : ''}`}>
        <Outlet />
      </div>
    </div>
  );
}

export default App;
