import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { users } from "@/services/user-service";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { format, toZonedTime } from "date-fns-tz";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import LoadingScreen from "@/components/loading/loading-screen";
import ErrorCard from "@/components/error-card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useDropzone } from "react-dropzone";
import { IoCloudUploadOutline } from "react-icons/io5";
import { TiDeleteOutline } from "react-icons/ti";
import { Label } from "@/components/ui/label";
import LogFeed from "@/components/log-feed";

const ItTaskView = () => {
  const { language } = useLanguage();
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [task, setTask] = useState<any>({
    id: 1,
    title: "Task 1",
    description: "Task 1 Description",
    created_at: "2021-10-10T10:10:10Z",
    status: "pending",
    assigned_to: {
      id: 1,
      name: "User 1",
      email: "email@ds.com",
    },
    logs: [
      {
        id: 1,
        user_id: 1,
        task_id: 1,
        comment: "Task 1 Description",
        created_at: "2021-10-10T10:10:10Z",
        created_by: {
            id: 1,
            full_name: "Khaled Ezzi",
            email: "test@gmail.com",
        },
        attachments: [],
      },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [note, setNote] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [attachment, setAttachment] = useState<any>([]);

  const { data: usersData, isLoading: usersLoading } = useQuery({
    queryKey: ["users"],
    queryFn: users.simple,
  });

  useEffect(() => {
    document.title = "Lazem GO - IT Task";
  }, []);

  function formatDate(isoDate: any) {
    const timeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Riyadh";
    let formattedDateTime = format(
      toZonedTime(isoDate, timeZone),
      "MMMM d, yyyy hh:mm a",
      { timeZone }
    );
    return formattedDateTime;
  }

  const onDrop = (acceptedFiles: any) => {
    setAttachment((prevState: any[]) => [...prevState, ...acceptedFiles]);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {usersLoading && <LoadingScreen />}

      {error && (
        <ErrorCard
          message={error}
          onClick={() => {
            setError("");
          }}
        />
      )}

      <div className="flex mt-10 lg:w-1/2 mx-auto">
        <Button
          variant={"secondary"}
          onClick={() => {
            navigate("/it-tasks");
          }}
          className="w-full"
        >
          {language === "en" ? "Back" : "رجوع"}
        </Button>
      </div>

      <Card className="w-full lg:w-1/2 mx-auto mb-10">
        <Dialog>
          <DialogTrigger className="w-full">
            <div className="flex justify-start mx-4 my-6">
              <Button>{language === "en" ? "Add Note" : "إضافة ملاحظة"}</Button>
            </div>
          </DialogTrigger>
          <DialogContent dir={language === "en" ? "ltr" : "rtl"}>
            <DialogHeader>
              <DialogTitle>
                {language === "en" ? "Add Note" : "إضافة ملاحظة"}
              </DialogTitle>
            </DialogHeader>
            <div className="text-start">
              <Label>{language === "en" ? "Note" : "ملاحظة"}</Label>
              <Textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              {/*<!-- Component: Dropzone file input --> */}
              <div className="relative my-6">
                <div
                  {...getRootProps({
                    className:
                      "relative flex cursor-pointer flex-col items-center gap-4 rounded border border-dashed border-slate-300 px-3 py-6 text-center text-sm font-medium transition-colors",
                  })}
                >
                  <input {...getInputProps()} />
                  <IoCloudUploadOutline
                    className="inline-flex h-12 items-center justify-center self-center"
                    size={24}
                  />
                  <span className="text-slate-500">
                    {language === "en"
                      ? "Drag and drop or Browse"
                      : "اسحب أو تصفح"}
                  </span>
                </div>
              </div>
              {attachment.length > 0 && (
                <div>
                  {attachment.map((file: any) => (
                    <div
                      key={file.path}
                      className="flex gap-6 items-center justify-between my-2"
                    >
                      <span>{file.path}</span>
                      <TiDeleteOutline
                        onClick={() => {
                          setAttachment(
                            attachment.filter((f: any) => f.path !== file.path)
                          );
                        }}
                        className="text-red-500 cursor-pointer"
                        size={24}
                      />
                    </div>
                  ))}
                </div>
              )}

              {/*<!-- End Dropzone file input --> */}
            </div>
            <Button>{language === "en" ? "Save" : "حفظ"}</Button>
          </DialogContent>
        </Dialog>
        <CardHeader>
          <CardTitle>{task.title}</CardTitle>
          <CardDescription>{task.description}</CardDescription>
        </CardHeader>
        <CardContent className="text-start">
          <div className="my-6">
            <div>
              <Label>{language === "en" ? "Status" : "الحالة"}</Label>
            </div>
            <div>
              <Label
                className={`
                            text-start w-full
                        ${
                          task.status === "completed"
                            ? "text-green-500"
                            : "text-red-500"
                        }
                        `}
              >
                {task.status}
              </Label>
            </div>
          </div>
          <div className="my-2">
            <Label>{language === "en" ? "Created At" : "تاريخ الإنشاء"}</Label>
            <div>{formatDate(task.created_at)}</div>
          </div>
          <div className="my-6">
            <Label className="w-full">
              {language === "en" ? "Assigned To" : "تعيين لـ"}
            </Label>
            <Select
                onValueChange={(value) => setSelectedUser(value)}
               
            >
              <SelectTrigger>
                <SelectValue
                  placeholder={
                    language === "en" ? "Select User" : "اختر مستخدم"
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {usersData &&
                    usersData.data?.map((user: any) => (
                      <SelectItem key={user.id} value={user.id}>
                        {user.full_name}
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </CardContent>
        <CardFooter>
          <Button
            className="w-full"
            disabled={!selectedUser}
          >
            {language === "en" ? "Save" : "حفظ"}
        </Button>
        </CardFooter>
        {task.logs && <LogFeed logs={task.logs} />}
      </Card>
    </div>
  );
};

export default ItTaskView;
