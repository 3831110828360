import { useLanguage } from "@/components/context/LanguageContext";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import ErrorCard from "@/components/error-card";
import { Label } from "@/components/ui/label";
import { getMyTasks } from "@/services/task-services";
import { useQuery } from "@tanstack/react-query";
import { format, toZonedTime } from 'date-fns-tz';

const TaskDashboard = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  // const [tasks, setTasks] = useState<any>();

  useEffect(() => {
    document.title = "Lazem GO - Tasks";
  }, []);

  const fetchTasks = async () => {
    return await getMyTasks()
      .then((response) => {
        // console.log('TASKS', response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  };
  const { isLoading: tasksLoading, data: tasks } = useQuery({
    queryKey: ["tasks"],
    queryFn: fetchTasks,
    refetchOnWindowFocus: false,
  });

  function formatDate(isoDate: any) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Riyadh';
    let formattedDateTime = format(toZonedTime(isoDate, timeZone), 'MMMM d, yyyy hh:mm a', { timeZone });
    return formattedDateTime;
  }

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {tasksLoading && error === "" && <LoadingScreen />}
      {error !== "" && <ErrorCard error={error} onClick={() => setError("")} />}
      {
        <>
          {tasks && (
            <>
              <div className="my-4">
                <h1 className="text-2xl font-bold">
                  {language === "en" ? "Tasks" : "المهام"}
                </h1>
              </div>

              <div className="">
                {tasks.length > 0 ? (
                  <>
                    <h1 className="text-2xl font-bold text-start my-4 mx-6">
                      {language === "en" ? "New Tasks" : "المهام الجديدة"}
                    </h1>
                    <div className="flex flex-wrap justify-start items-start gap-4">
                      {tasks.map((task: any) => (
                        <>
                          {task.status !== "completed" && (
                            <Card
                              key={task.id}
                              className="m-3 w-64 h-auto  cursor-pointer cursor-pointer"
                              onClick={() => navigate(`/tasks/id/${task.id}`)}
                            >
                              <CardHeader>
                                <CardDescription
                                  className={` text-start text-white p-1 rounded-md w-fit
                                  ${task.status === "completed"
                                      ? "bg-green-500"
                                      : task.status === "new"
                                        ? "bg-yellow-500"
                                        : "bg-red-500"
                                    }`}
                                >
                                  {task.status}
                                </CardDescription>
                                <CardTitle>{task.title}</CardTitle>
                              </CardHeader>
                              <CardContent>
                                <CardDescription className="text-start flex flex-col gap-4 justify-center items-center">
                                  <Label>{task.description}</Label>
                                  <Label>
                                    {language === "en"
                                      ? "Start Date"
                                      : "تاريخ ابتداء المهمة"}
                                    :
                                    <br />
                                    <br />
                                    {" " + formatDate(task.start_date)}
                                  </Label>
                                  <Label>
                                    {language === "en"
                                      ? "End Date"
                                      : "تاريخ انتهاء المهمة"}
                                    :
                                    <br />
                                    <br />
                                    {" " + formatDate(task.end_date)}
                                  </Label>
                                </CardDescription>
                              </CardContent>
                            </Card>
                          )}
                        </>
                      ))}
                    </div>
                    <h1 className="text-2xl font-bold text-start my-4 mx-6">
                      {language === "en"
                        ? "Completed Tasks"
                        : "المهام المكتملة"}
                    </h1>
                    <div className="flex flex-wrap justify-start items-start gap-4">
                      {tasks.map((task: any) => (
                        <>
                          {task.status === "completed" && (
                            <Card
                              key={task.id}
                              className="m-3 w-64 h-auto  cursor-pointer cursor-pointer"
                              onClick={() => navigate(`/tasks/id/${task.id}`)}
                            >
                              <CardHeader>
                                <CardDescription
                                  className={` text-start text-white p-1 rounded-md w-fit
                                  ${task.status === "completed"
                                      ? "bg-green-500"
                                      : task.status === "new"
                                        ? "bg-yellow-500"
                                        : "bg-red-500"
                                    }`}
                                >
                                  {task.status}
                                </CardDescription>
                                <CardTitle>{task.title}</CardTitle>
                              </CardHeader>
                              <CardContent>
                                <CardDescription className="text-start flex flex-col gap-4 justify-center items-center">
                                  <Label>{task.description}</Label>
                                  <Label>
                                    {language === "en"
                                      ? "Start Date"
                                      : "تاريخ ابتداء المهمة"}
                                    :
                                    <br />
                                    <br />
                                    {" " + formatDate(task.start_date)}
                                  </Label>
                                  <Label>
                                    {language === "en"
                                      ? "End Date"
                                      : "تاريخ انتهاء المهمة"}
                                    :
                                    <br />
                                    <br />
                                    {" " + formatDate(task.end_date)}
                                  </Label>
                                </CardDescription>
                              </CardContent>
                            </Card>
                          )}
                        </>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center">
                    <p>
                      {language === "en"
                        ? "No tasks found"
                        : "لم يتم العثور على مهام"}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
    </div>
  );
};

export default TaskDashboard;
