import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import ErrorCard from "@/components/error-card";
import LoadingScreen from "@/components/loading/loading-screen";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import LoadingIcon from "@/components/loading/loading-icon";
import {
  getStatuses,
  getProjectManagers,
  getProjectSpportTeams,
  getProjectType,
  projects,
} from "@/services/project-service";
import { FiRefreshCw } from "react-icons/fi";
import { getSites } from "@/services/site-services";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { MultiSelect } from "@/components/ui/multi-select";

const CreateProject = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [project, setProject] = useState<any>({
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    project_manager: "",
    type: "",
    status: "",
    site: "",
    support_team: [],
  });
  const [systemError, setSystemError] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [projectManagers, setProjectManagers] = useState<any>();
  const [supportTeams, setSupportTeams] = useState<any>();
  const [projectTypes, setProjectTypes] = useState<any>();
  const [projectStatuses, setProjectStatuses] = useState<any>();
  const [sites, setSites] = useState<any>();
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [isSiteLoading, setIsSiteLoading] = useState(false);
  const getData = async () => {
    if (!projectStatuses) {
      await fetchStatuses()
        .then(async () => {
          await fetchManagers();
          await fetchSupportTeams();
          await fetchTypes();
          await fetchSites();
        })
        .catch((error) => {
          setSystemError(error.message);
        });
    }
  };

  useEffect(() => {
    document.title = "Lazem GO - Create Project";
  } , []);

  const { isLoading: projectsLoading } = useQuery({
    queryKey: ["create project"],
    queryFn: getData,
  });

  const fetchManagers = async () => {
    await getProjectManagers()
      .then((response: any) => {
        let managers = [];
        for (let i = 0; i < response.data.length; i++) {
          managers.push({
            id: response.data[i].id,
            title:
              response.data[i].full_name,
          });
        }
        setProjectManagers(managers);
      })
      .catch((error) => {
        console.log("Error", error);
        setSystemError(
          language === "en" ? "Error fetching managers" : "خطأ في جلب المدراء"
        );
      });
  };

  const fetchSites = async () => {
    setIsSiteLoading(true);
    await getSites()
      .then((response: any) => {
        let sites = [];
        for (let i = 0; i < response.data.length; i++) {
          sites.push({
            id: response.data[i].id,
            title: response.data[i].name,
          });
        }
        setSites(sites);
      })
      .catch((error: any) => {
        setSystemError(error.message);
      });
    setIsSiteLoading(false);
  };

  const fetchTypes = async () => {
    setIsTypeLoading(true);
    await getProjectType()
      .then((response: any) => {
        let types = [];
        for (let i = 0; i < response.data.length; i++) {
          types.push({
            id: response.data[i].id,
            title: response.data[i].title,
          });
        }
        setProjectTypes(types);
      })
      .catch((error) => {
        console.log("Error", error);
        setSystemError(
          language === "en"
            ? "Error fetching project types"
            : "خطأ في جلب أنواع المشروع"
        );
      })
      .finally(() => {
        setIsTypeLoading(false);
      });
  };

  const fetchSupportTeams = async () => {
    await getProjectSpportTeams()
      .then((response: any) => {
        let teams = [];
        for (let i = 0; i < response.data.length; i++) {
          teams.push({
            id: response.data[i].id,
            value: response.data[i].id,
            label:
              response.data[i].full_name,
            title:
              response.data[i].full_name
          });
        }
        setSupportTeams(teams);
      })
      .catch((error) => {
        console.log("Error", error);
        setSystemError(
          language === "en"
            ? "Error fetching support teams"
            : "خطأ في جلب فرق الدعم"
        );
      });
  };

  const fetchStatuses = async () => {
    setIsStatusLoading(true);
    await getStatuses()
      .then((response: any) => {
        setProjectStatuses(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
        setSystemError(
          language === "en" ? "Error fetching statuses" : "خطأ في جلب الحالات"
        );
      })
      .finally(() => {
        setIsStatusLoading(false);
      });
  };

  const setInputField = (
    label: string,
    field: string,
    value: any,
    type?: string
  ) => {
    return (
      <div className="flex flex-col my-4">
        <Label className="mb-1">{label}</Label>
        <Input
          type={type ? type : "text"}
          value={value}
          onChange={(e) => setProject({ ...project, [field]: e.target.value })}
        />
      </div>
    );
  };

  const setSelectField = (
    label: string,
    field: string,
    options: any,
    with_add?: boolean,
    url?: string
  ) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <div className="flex gap-2">
          <div className={`${with_add ? "w-4/5 " : "w-full"}`}>
            <Select
              onValueChange={(value: any) => {
                // console.log('Value', value);
                setProject({ ...project, [field]: value });
              }}
            >
              <SelectTrigger>
                <SelectValue
                  placeholder={
                    language === "en" ? "Select" + label : "اختر" + label
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>
                    {language === "en" ? "Select" + label : "اختر" + label}
                  </SelectLabel>
                  {options?.map((option: any) => (
                    <SelectItem key={option.id} value={option.id}>
                      {option.title}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div>
            {with_add && (
              <Button
                variant="outline"
                onClick={() => functionToCall(field)}
                className=""
              >
                <FiRefreshCw />
              </Button>
            )}
          </div>
        </div>
        {with_add && (
          <Button
            variant="link"
            className="text-sm flex justify-start w-fit"
            onClick={() => window.open(url ? url : "", "_blank")}
          >
            {language === "en" ? "Add New" : "إضافة جديد"}
          </Button>
        )}
      </div>
    );
  };

  const functionToCall = (field: string) => {
    if (field === "status") {
      fetchStatuses();
    } else if (field === "type") {
      fetchTypes();
    } else if (field === "site") {
      fetchSites();
    }
  };

  const setMultiSelectField = (label: string, field: string, options: any) => {
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <MultiSelect
          defaultValue={project[field]}
          options={options}
          onValueChange={(value: any) => {
            // console.log('Value', value);
            setProject({ ...project, [field]: value });
          }}
          animation={2}
          className="w-full"
        />
      </div>
    );
  };

  const checkIfFormValid = () => {
    if (project.title === "") {
      setFormError(language === "en" ? "Title is required" : "العنوان مطلوب");
      return true;
    } else if (project.project_manager === "") {
      setFormError(
        language === "en" ? "Project Manager is required" : "المدير مطلوب"
      );
      return true;
    } else if (project.type === "") {
      setFormError(language === "en" ? "Type is required" : "النوع مطلوب");
      return true;
    } else if (project.support_team.length === 0) {
      setFormError(
        language === "en" ? "Support Team is required" : "فريق الدعم مطلوب"
      );
      return true;
    }

    setFormError("");
    return false;
  };

  const handleCreateProject = (e: any) => {
    e.preventDefault();
    if (checkIfFormValid()) {
      return;
    } else {
      let data_to_send: any = {
        title: project.title,
        description: project.description,
        project_manager: project.project_manager,
        type: project.type,
        status: project.status,
        support_team: project.support_team,
        site: project.site,
      };
      if (project.start_date !== "") {
        data_to_send = {
          ...data_to_send,
          start_date: new Date(project.start_date).toISOString(),
        };
      }
      if (project.end_date !== "") {
        data_to_send = {
          ...data_to_send,
          end_date: new Date(project.end_date).toISOString(),
        };
      }
      e.preventDefault();
      setFormLoading(true);
      projects
        .create(data_to_send)
        .then((res) => {
          navigate("/projects/id/" + res.data.id);
        })
        .catch((error) => {
          console.log("Error", error);
          setFormError(
            language === "en"
              ? "Error creating project"
              : "خطأ في إنشاء المشروع"
          );
        })
        .finally(() => {
          setFormLoading(false);
        });
    }
  };

  return (
    <div
        dir={language === 'en' ? 'ltr' : 'rtl'}
    >
      {projectsLoading === true && systemError !== "" && <LoadingScreen />}
      {systemError !== "" && (
        <ErrorCard error={systemError} onClick={() => setSystemError("")} />
      )}
      {
        <div
          className="w-full lg:w-3/4 p-5 m-auto"
          dir={language === "en" ? "ltr" : "rtl"}
        >
          <div className="">
            <div className="flex justify-end mb-4"></div>
          </div>
          <Card className="w-full lg:w-[600px] m-auto">
            <CardHeader className="text-center">
              <Button
                onClick={() => navigate("/projects")}
                variant={"outline"}
                className="mb-6"
              >
                {language === "en" ? "Back" : "رجوع"}
              </Button>
              <CardTitle className="text-center">
                {language === "en" ? "Create Project" : "إنشاء مشروع"}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Card className="text-start w-full p-4">
                <CardHeader className="text-center">
                  <CardTitle>
                    {language === "en"
                      ? "Project Information"
                      : "معلومات المشروع"}
                  </CardTitle>
                </CardHeader>
                {setInputField(
                  language === "en" ? "Title" : "العنوان",
                  "title",
                  project.title
                )}
                {setInputField(
                  language === "en" ? "Description" : "الوصف",
                  "description",
                  project.description
                )}
                {setInputField(
                  language === "en" ? "Start Date" : "تاريخ البدء",
                  "start_date",
                  project.start_date,
                  "date"
                )}
                {setInputField(
                  language === "en" ? "End Date" : "تاريخ الانتهاء",
                  "end_date",
                  project.end_date,
                  "date"
                )}
                {setSelectField(
                  language === "en" ? "Project Manager" : "المدير",
                  "project_manager",
                  projectManagers
                )}
                {isStatusLoading ? (
                  <div className="flex justify-center align-items-center gap-2">
                    <LoadingIcon />
                  </div>
                ) : (
                  <>
                    {setSelectField(
                      language === "en" ? "Status" : "الحالة",
                      "status",
                      projectStatuses,
                      true,
                      "/projects/create-status"
                    )}
                  </>
                )}
              </Card>
              <Card className="text-start w-full p-4 mt-4">
                <CardHeader className="text-center">
                  <CardTitle>
                    {language === "en" ? "Sites" : "المواقع"}
                  </CardTitle>
                </CardHeader>
                {isSiteLoading ? (
                  <div className="flex justify-center align-items-center gap-2">
                    <LoadingIcon />
                  </div>
                ) : (
                  <>
                    {setSelectField(
                      language === "en" ? "Site" : "الموقع",
                      "site",
                      sites,
                      true,
                      "/sites/create"
                    )}
                  </>
                )}
              </Card>
              <Card className="text-start w-full p-4 mt-4">
                <CardHeader className="text-center">
                  <CardTitle>
                    {language === "en" ? "Support Team" : "فريق الدعم"}
                  </CardTitle>
                </CardHeader>
                {setMultiSelectField(
                  language === "en" ? "Support Team" : "فريق الدعم",
                  "support_team",
                  supportTeams || []
                )}
              </Card>

              <Card className="text-start w-full p-4 mt-4">
                <CardHeader className="text-center">
                  <CardTitle>{language === "en" ? "Type" : "النوع"}</CardTitle>
                </CardHeader>
                {isTypeLoading ? (
                  <div className="flex justify-center align-items-center gap-2">
                    <LoadingIcon />
                  </div>
                ) : (
                  <>
                    {setSelectField(
                      language === "en" ? "Type" : "النوع",
                      "type",
                      projectTypes,
                      true,
                      "/projects/create-project-type"
                    )}
                  </>
                )}
              </Card>

              {formError !== "" && (
                <p className="text-red-500 text-sm">{formError}</p>
              )}
              <Button
                className="w-full mt-4 flex justify-center align-items-center gap-2"
                onClick={handleCreateProject}
                disabled={formLoading}
              >
                {formLoading ? (
                  <>
                    <LoadingIcon />
                    {language === "en" ? "Creating" : "جار الإنشاء"}
                  </>
                ) : (
                  <>{language === "en" ? "Create" : "إنشاء"}</>
                )}
              </Button>
            </CardContent>
          </Card>
        </div>
      }
    </div>
  );
};

export default CreateProject;
