import { useLanguage } from "@/components/context/LanguageContext";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingScreen from "@/components/loading/loading-screen";
import ErrorCard from "@/components/error-card";
import { Label } from "@/components/ui/label";
import { getSites } from "@/services/site-services";
import { useQuery } from "@tanstack/react-query";

const SiteDashboard = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [error, setError] = useState("");
  const [sites, setSites] = useState<any>();

  useEffect(() => {
    document.title = "Lazem GO - Sites";
  } , []);

  const fetchSites = async () => {
    if (!sites) {
      await getSites()
        .then((response: any) => {
          setSites(response.data);
        })
        .catch((error: any) => {
          setError(error.message);
        });
    }
  };

  const { isLoading: siteLoading } = useQuery({
    queryKey: ["stages"],
    queryFn: fetchSites,
  });

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {siteLoading === true && error === "" && <LoadingScreen />}
      {error !== "" && <ErrorCard error={error} onClick={() => setError("")} />}
      {
        <div>
          <>
            <CardHeader>
              <Button
                onClick={() => navigate("/sites/create")}
                className="w-fit "
              >
                {language === "en" ? "Create Site" : "إنشاء موقع"}
              </Button>
              <CardTitle>{language === "en" ? "Sites" : "المواقع"}</CardTitle>
            </CardHeader>
            <CardContent>
              {sites && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {sites.map((site: any) => (
                    <Card key={site.id}>
                      <CardHeader>
                        <CardTitle>{site.name}</CardTitle>
                      </CardHeader>
                      <CardContent className="text-start">
                        <div className="mb-4">
                          <Label>
                            {language === "en" ? "Description" : "الوصف"}
                          </Label>
                          <p>{site.description}</p>
                        </div>
                        <div className="mb-4 flex flex-col">
                          <Label>
                            {language === "en" ? "URL Location" : "رابط الموقع"}
                          </Label>
                          <Button
                            onClick={() =>
                              window.open(
                                site.url_location.startsWith("http")
                                  ? site.url_location
                                  : `http://${site.url_location}`,
                                "_blank"
                              )
                            }
                            variant={"link"}
                          >
                            <p className="w-4/5 truncate text-start">
                              {site.url_location}
                            </p>
                          </Button>
                        </div>
                      </CardContent>
                      <CardFooter>
                        <Button
                          onClick={() => navigate(`/sites/id/${site.id}`)}
                        >
                          {language === "en" ? "View Site" : "عرض الموقع"}
                        </Button>
                      </CardFooter>
                    </Card>
                  ))}
                </div>
              )}
            </CardContent>
          </>
        </div>
      }
    </div>
  );
};

export default SiteDashboard;
