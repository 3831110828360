import ApiUrl from "./api-url";
import axios, {isCancel} from 'axios';
import {ApiHeaderOptions} from "./api-header-options";
import { users } from "./user-service";

const getSite = async (id:any) => {
    const url = `${ApiUrl}/v1/sites/sites/${id}/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getSites = async () => {
    const url = `${ApiUrl}/v1/sites/sites/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const createSite = async (data:any) => {
    const url = `${ApiUrl}/v1/sites/sites/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getSiteMembers = async () => {
    const url = `${ApiUrl}/v1/sites/site_members/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const getSiteMember = async (id:any) => {
    const url = `${ApiUrl}/v1/sites/site_members/${id}/`;
    return await axios.get(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const createSiteMember = async (data:any) => {
    /* 
    data = {
        "site": site_id,
        "user": user_id,
        "role": "member" or "manager",

    }

    */
    const url = `${ApiUrl}/v1/sites/site_members/`;
    return await axios.post(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const editSite = async (id:any, data:any) => {
    const url = `${ApiUrl}/v1/sites/sites/${id}/`;
    return await axios.patch(url, data, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

const deleteSite = async (id:any) => {
    const url = `${ApiUrl}/v1/sites/site_members/${id}/`;
    return await axios.delete(url, ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error:any) => {
            if (error?.response?.data?.code === 'token_not_valid') {
                console.log('Token not valid', error);
                users.refreshToken();
                throw error;
            }
            else {
                console.log('Form Error', error);
                throw error;
            }
    });
}

export {getSite, getSites, createSite, editSite, getSiteMembers, getSiteMember, createSiteMember, deleteSite};