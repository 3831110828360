import React from 'react'
import ReactDOM from 'react-dom/client'
// import { BrowserRouter } from "react-router-dom";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  BrowserRouter
} from "react-router-dom";
import App from './App.tsx'
import './index.css'
import { ThemeProvider } from './components/theme-provider.tsx';
import { LanguageProvider } from './components/context/LanguageContext.tsx';
import { AuthProvider } from './components/context/AuthContext.tsx';
import Router from './Router.tsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from "@sentry/react";

const env_type = process.env.ENVIRONMENT || 'localhost';


if (env_type != 'localhost') {
  Sentry.init({
    dsn: "https://accaa1e3328297852799693d7c77bd20@o4507779368353792.ingest.de.sentry.io/4507779396796496",
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.feedbackIntegration({
        // Additional SDK configuration goes in here, for example:
        colorScheme: "system",
      }),
    ],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    environment: env_type,
  });

}
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
        <LanguageProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>

              <Router />
          </AuthProvider>
          </QueryClientProvider>
        </LanguageProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
)
