import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import ErrorCard from "@/components/error-card";
import LoadingScreen from "@/components/loading/loading-screen";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import LoadingIcon from "@/components/loading/loading-icon";
import {
  createProjectType,
  getStages,
  getTaskTemplates,
  getDepartments,
} from "@/services/project-service";
import { FiRefreshCw } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import { formTemplates } from "@/services/forms-service";

const CreateProjectType = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [systemError, setSystemError] = useState("");
  const [formError, setFormError] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [type, setType] = useState<any>({
    title: "",
    description: "",
    task_stages: [],
  });
  const [fetchedTaskTemplates, setFetchedTaskTemplates] = useState<any>();
  const [isCreateTaskTemplate, setIsCreateTaskTemplate] = useState(false);
  const [fetchedProjectStages, setFetchedProjectStages] = useState();
  const [fetchedDepartments, setFetchedDepartments] = useState();
  const [isFetchingDepartments, setIsFetchingDepartments] = useState(false);
  const [taskTemplatesType] = useState([
    {
      value: "submit",
      display_name: "submit",
    },
    {
      value: "form",
      display_name: "form",
    },
    {
      value: "approval",
      display_name: "approval",
    },
    {
      value: "function",
      display_name: "function",
    },
  ]);
  const [taskTemplatesAssignedGroup] = useState([
    {
      value: "department",
      display_name: "department",
    },
    {
      value: "site",
      display_name: "site",
    },
    {
      value: "project",
      display_name: "project",
    },
    {
      value: "custom",
      display_name: "custom",
    },
  ]);
  const [taskTemplatesGroupRole] = useState([
    {
      value: "manager",
      display_name: "manager",
    },
    {
      value: "all",
      display_name: "all",
    },
    {
      value: "custom",
      display_name: "custom",
    },
  ]);
  const [fetchedFormTemplates, setFetchedFormTemplates] = useState<any>();
  const [isFetchingFormTemplates, setIsFetchingFormTemplates] = useState(false);

  useEffect(() => {
    document.title = "Lazem GO - Create Project Type";
  }, [fetchedProjectStages, fetchedDepartments]);

  const fetchDepartments = async () => {
    setIsFetchingDepartments(true);
    await getDepartments()
      .then((response: any) => {
        // console.log("Departments", response.data);
        let departments: any = [];
        for (let i = 0; i < response.data.length; i++) {
          departments.push({
            value: response.data[i].id,
            display_name: response.data[i].title,
          });
        }
        // console.log(departments);
        setFetchedDepartments(departments);
      })
      .catch((error) => {
        console.log("Error", error);
        setSystemError(
          language === "en"
            ? "Error fetching departments"
            : "خطأ في جلب الأقسام"
        );
      })
      .finally(() => {
        setIsFetchingDepartments(false);
      });
  };

  const fetchStages = async () => {
    await getStages()
      .then((response: any) => {
        // console.log("stages",response.data);
        let stages: any = [];
        for (let i = 0; i < response.data.length; i++) {
          stages.push({
            value: response.data[i].id,
            display_name: response.data[i].title,
          });
        }
        // console.log(stages);
        setFetchedProjectStages(stages);
      })
      .catch((error) => {
        console.log("Error", error);
        setSystemError(
          language === "en" ? "Error fetching stages" : "خطأ في جلب المراحل"
        );
      });
  };

  const fetchTaskTemplates = async () => {
    await getTaskTemplates()
      .then((response: any) => {
        // console.log('Tem',response);
        setFetchedTaskTemplates(response.data);
      })
      .catch((error) => {
        console.log("Error", error);
        setSystemError(
          language === "en"
            ? "Error fetching task templates"
            : "خطأ في جلب قوالب المهمة"
        );
      });
  };

  const fetchFormTemplates = async () => {
    // console.log('Fetching Forms');
    setIsFetchingFormTemplates(true);
    await formTemplates
      .list()
      .then((response) => {
        // console.log('Forms', response.data);
        let forms: any = [];
        for (let i = 0; i < response.data.length; i++) {
          forms.push({
            value: response.data[i].id,
            display_name: response.data[i].title,
          });
        }
        setFetchedFormTemplates(forms);
      })
      .catch((error) => {
        console.log(error);
        setSystemError(error.message);
      })
      .finally(() => {
        setIsFetchingFormTemplates(false);
      });
  };

  const getData = async () => {
    if (!fetchedProjectStages) {
      await fetchStages();
      await fetchDepartments();
      await fetchTaskTemplates();
    }
  };

  const { isLoading: stagesLoading } = useQuery({
    queryKey: ["create project type"],
    queryFn: getData,
  });

  const handleChange = (
    e: any,
    table: any,
    taskStageIndex = null,
    templateIndex = null
  ) => {
    const { name, value } = e.target;

    if (table === "type") {
      setType({
        ...type,
        [name]: value,
      });
    } else if (table === "taskStages" && taskStageIndex !== null) {
      const updatedTaskStages = type.task_stages.map(
        (taskStage: any, index: any) => {
          if (index === taskStageIndex) {
            return {
              ...taskStage,
              [name]: value,
            };
          }
          return taskStage;
        }
      );

      setType({
        ...type,
        task_stages: updatedTaskStages,
      });
    } else if (
      table === "taskTemplates" &&
      taskStageIndex !== null &&
      templateIndex !== null
    ) {
      const updatedTaskStages = type.task_stages.map(
        (taskStage: any, index: any) => {
          if (index === taskStageIndex) {
            const updatedTaskTemplates = taskStage.task_templates.map(
              (template: any, i: any) => {
                if (i === templateIndex) {
                  return {
                    ...template,
                    [name]: value,
                  };
                }
                return template;
              }
            );

            return {
              ...taskStage,
              task_templates: updatedTaskTemplates,
            };
          }
          return taskStage;
        }
      );

      setType({
        ...type,
        task_stages: updatedTaskStages,
      });
    }
  };

  const handleAddTaskStage = () => {
    let newTaskStage = {
      title: "",
      description: "",
      stage: "",
      task_templates: [],
      index : type.task_stages.length + 1
    };
    setType((prevType: any) => ({
      ...prevType,
      task_stages: [...prevType.task_stages, newTaskStage],
    }));
  };

  const handleAddTaskTemplate = (taskStageIndex: any) => {
    const newTaskTemplate = {
      title: "",
      description: "",
      type: null,
      assigned_group: null,
      duration_minutes: null,
      start_time: null,
      group_role: null,
      department: null,
    };

    const updatedTaskStages = type.task_stages.map(
      (taskStage: any, index: any) => {
        if (index === taskStageIndex) {
          return {
            ...taskStage,
            task_templates: [...taskStage.task_templates, newTaskTemplate],
          };
        }
        return taskStage;
      }
    );

    setType({
      ...type,
      task_stages: updatedTaskStages,
    });
  };

  const handleRemoveTaskStage = (index: any) => {
    const updatedTaskStages = type.task_stages.filter(
      (_: any, i: any) => i !== index
    );
    setType({
      ...type,
      task_stages: updatedTaskStages,
    });
  };

  const handleRemoveTaskTemplate = (
    taskStageIndex: any,
    templateIndex: any
  ) => {
    const updatedTaskStages = type.task_stages.map((taskStage: any, i: any) => {
      if (i === taskStageIndex) {
        const updatedTaskTemplates = taskStage.task_templates.filter(
          (_: any, j: any) => j !== templateIndex
        );
        // console.log(updatedTaskTemplates);
        if (updatedTaskTemplates.length === 0) {
          setIsCreateTaskTemplate(false);
        }
        return {
          ...taskStage,
          task_templates: updatedTaskTemplates,
        };
      }
      return taskStage;
    });

    setType({
      ...type,
      task_stages: updatedTaskStages,
    });
  };

  const validateForm = () => {
    let isValid = true;
    let errors = [];

    if (!type.title) {
      errors.push(
        language === "en"
          ? "Project title is required."
          : "عنوان المشروع مطلوب."
      );
      isValid = false;
    }

    if (!type.description) {
      errors.push(
        language === "en"
          ? "Project description is required."
          : "وصف المشروع مطلوب."
      );
      isValid = false;
    }

    if (type.task_stages.length === 0) {
      errors.push(
        language === "en"
          ? "At least one task stage is required."
          : "مطلوب مرحلة واحدة على الأقل."
      );
      isValid = false;
    } else {
      type.task_stages.forEach((taskStage: any, taskStageIndex: any) => {
        if (!taskStage.title) {
          errors.push(
            `${
              language === "en"
                ? "Task stage title is required for task stage"
                : "عنوان مرحلة المهمة مطلوب لمرحلة المهمة"
            } ${taskStageIndex + 1}.`
          );
          isValid = false;
        }
        if (!taskStage.stage) {
          errors.push(
            `${
              language === "en"
                ? "Stage is required for task stage"
                : "المرحلة مطلوبة لمرحلة المهمة"
            } ${taskStageIndex + 1}.`
          );
          isValid = false;
        }
        if (isCreateTaskTemplate && taskStage.task_templates.length === 0) {
          errors.push(
            `${
              language === "en"
                ? "At least one task template is required for task stage"
                : "مطلوب قالب مهمة واحد على الأقل لمرحلة المهمة"
            } ${taskStageIndex + 1}.`
          );
          isValid = false;
        } else {
          if (isCreateTaskTemplate) {
            taskStage.task_templates.forEach(
              (taskTemplate: any, templateIndex: any) => {
                if (!taskTemplate.title) {
                  errors.push(
                    `${
                      language === "en"
                        ? "Task template title is required for task stage"
                        : "عنوان قالب المهمة مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${
                      language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                } else if (!taskTemplate.department) {
                  errors.push(
                    `${
                      language === "en"
                        ? "Department is required for task stage"
                        : "القسم مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${
                      language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                } else if (!taskTemplate.group_role) {
                  errors.push(
                    `${
                      language === "en"
                        ? "Group role is required for task stage"
                        : "دور المجموعة مطلوب لمرحلة المهمة"
                    } ${taskStageIndex + 1}, ${
                      language === "en" ? "template" : "القالب"
                    } ${templateIndex + 1}.`
                  );
                  isValid = false;
                }
              }
            );
          }
        }
      });
    }

    setFormError(errors.join("\n"));
    return isValid;
  };

  const setTaskForms = () => {
    return (
      <div className="flex flex-col my-6 p-2">
        <CardTitle className="text-xl font-bold text-center my-2">
          {language === "en" ? "Task Stages" : "مراحل المهمة"}
        </CardTitle>

        <>
          {type.task_stages.map((taskStage: any, index: any) => (
            <Card
              key={index}
              className="flex gap-2 flex-wrap justify-between my-4 p-2"
            >
              <div className="flex justify-end w-full">
                <Button
                  variant={"destructive"}
                  onClick={() => handleRemoveTaskStage(index)}
                >
                  <MdDeleteForever
                    className="hover:text-red-500"
                    size={20}
                    title="Delete Task Stage"
                  />
                </Button>
              </div>
              <div className="w-full">
                <CardTitle className="text-xl font-bold text-center my-2">
                  {language === "en" ? "Stage Number" : "رقم المرحلة"}{" "}
                  {index + 1}
                </CardTitle>
              </div>
              <div className="w-full lg:w-2/5">
                {setInputField(
                  language === "en" ? "Task Stage Title" : "عنوان مرحلة المهمة",
                  "title",
                  taskStage.title,
                  "taskStages",
                  "text",
                  index
                )}
              </div>
              <div className="w-full lg:w-2/5">
                {setInputField(
                  language === "en" ? "Description" : "الوصف",
                  "description",
                  taskStage.description,
                  "taskStages",
                  "text",
                  index
                )}
              </div>
              <div className="w-full">
                {setSelectField(
                  language === "en" ? "Stage" : "المرحلة",
                  "stage",
                  taskStage.stage,
                  fetchedProjectStages,
                  "taskStages",
                  index,
                  null,
                  true,
                  "/projects/create-stage"
                )}
              </div>

              {setTemplateForms(index)}
            </Card>
          ))}
          <div>
            <Button onClick={handleAddTaskStage}>
              {language === "en" ? "Add Task Stage" : "إضافة مرحلة المهمة"}
            </Button>
          </div>
        </>
      </div>
    );
  };

  const setTemplateForms = (taskStageIndex: any) => {
    return (
      <div key={taskStageIndex} className="flex flex-col my-4 p-2 w-full">
        <CardTitle className="text-xl font-bold text-center my-2">
          {language === "en" ? "Task Templates" : "قوالب المهمة"}
        </CardTitle>
        {fetchedTaskTemplates.length === 0 || isCreateTaskTemplate ? (
          <>
            {fetchedTaskTemplates.length !== 0 && (
              <div className="flex justify-start">
                <Button
                  onClick={() => {
                    setType({
                      ...type,
                      task_stages: type.task_stages.map(
                        (taskStage: any, i: any) => {
                          if (i === taskStageIndex) {
                            return {
                              ...taskStage,
                              task_templates: [],
                            };
                          }
                          return taskStage;
                        }
                      ),
                    });
                    setIsCreateTaskTemplate(false);
                  }}
                  className="text-sm w-fit h-fit"
                  variant={"secondary"}
                >
                  {language === "en"
                    ? "Select Task Template"
                    : "اختر قالب المهمة"}
                </Button>
              </div>
            )}
            <div className="flex flex-col">
              {type.task_stages[taskStageIndex].task_templates.map(
                (taskTemplate: any, templateIndex: any) => (
                  <Card
                    key={templateIndex}
                    className="flex gap-2 flex-wrap justify-between  my-4 p-2"
                  >
                    <div className="flex justify-end w-full">
                      <Button
                        variant={"destructive"}
                        onClick={() =>
                          handleRemoveTaskTemplate(
                            taskStageIndex,
                            templateIndex
                          )
                        }
                      >
                        <MdDeleteForever
                          className="hover:text-red-500"
                          size={20}
                          title="Delete Task Template"
                        />
                      </Button>
                    </div>
                    <div className="w-full">
                      <CardTitle className="text-xl font-bold text-center my-2">
                        {language === "en" ? "Template Number" : "رقم القالب"}{" "}
                        {templateIndex + 1}
                      </CardTitle>
                    </div>
                    <div className="w-full lg:w-2/5">
                      {setInputField(
                        language === "en" ? "Title" : "العنوان",
                        "title",
                        taskTemplate.title,
                        "taskTemplates",
                        "text",
                        taskStageIndex,
                        templateIndex
                      )}
                    </div>
                    <div className="w-full lg:w-2/5">
                      {setInputField(
                        language === "en" ? "Description" : "الوصف",
                        "description",
                        taskTemplate.description,
                        "taskTemplates",
                        "text",
                        taskStageIndex,
                        templateIndex
                      )}
                    </div>
                    <div className="w-full lg:w-2/5">
                      {setSelectField(
                        language === "en" ? "Type" : "النوع",
                        "type",
                        taskTemplate.type,
                        taskTemplatesType,
                        "taskTemplates",
                        taskStageIndex,
                        templateIndex
                      )}
                      {taskTemplate.type === "form" && (
                        <div>
                          {isFetchingFormTemplates ? (
                            <LoadingIcon />
                          ) : (
                            <>
                              {setSelectField(
                                language === "en" ? "Form" : "النموذج",
                                "form_template",
                                taskTemplate.form,
                                fetchedFormTemplates,
                                "taskTemplates",
                                taskStageIndex,
                                templateIndex,
                                true,
                                "/forms/create"
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="w-full lg:w-2/5">
                      {setSelectField(
                        language === "en"
                          ? "Assigned Group"
                          : "المجموعة المعينة",
                        "assigned_group",
                        taskTemplate.assigned_group,
                        taskTemplatesAssignedGroup,
                        "taskTemplates",
                        taskStageIndex,
                        templateIndex
                      )}
                    </div>
                    <div className="w-full lg:w-2/5">
                      {setInputField(
                        language === "en" ? "Duration Minutes" : "مدة الدقائق",
                        "duration_minutes",
                        taskTemplate.duration_minutes,
                        "taskTemplates",
                        "number",
                        taskStageIndex,
                        templateIndex
                      )}
                    </div>
                    <div className="w-full lg:w-2/5">
                      {setInputField(
                        language === "en" ? "Start Time" : "وقت البدء",
                        "start_time",
                        taskTemplate.start_time,
                        "taskTemplates",
                        "time",
                        taskStageIndex,
                        templateIndex
                      )}
                    </div>
                    <div className="w-full">
                      {isFetchingDepartments ? (
                        <LoadingIcon />
                      ) : (
                        setSelectField(
                          language === "en" ? "Department" : "القسم",
                          "department",
                          taskTemplate.department,
                          fetchedDepartments,
                          "taskTemplates",
                          taskStageIndex,
                          templateIndex,
                          true,
                          "/projects/create-department"
                        )
                      )}
                    </div>
                    <div className="w-full">
                      {setSelectField(
                        language === "en"
                          ? "Department Group Role"
                          : "دور المجموعة في القسم",
                        "group_role",
                        taskTemplate.group_role,
                        taskTemplatesGroupRole,
                        "taskTemplates",
                        taskStageIndex,
                        templateIndex
                      )}
                    </div>
                  </Card>
                )
              )}
            </div>

            <div className="w-full flex justify-center">
              <Button
                onClick={() => {
                  handleAddTaskTemplate(taskStageIndex);
                }}
              >
                {language === "en" ? "Add New Template" : "إضافة قالب جديد"}
              </Button>
            </div>
          </>
        ) : (
          <Card className="flex flex-col gap-4 p-2">
            <div>
              <Select
                onValueChange={(value) => {
                  // console.log(value);
                  setIsCreateTaskTemplate(false);
                  const updatedTaskStages = type.task_stages.map(
                    (taskStage: any, i: any) => {
                      if (i === taskStageIndex) {
                        return {
                          ...taskStage,
                          task_templates: [value],
                        };
                      }
                      return taskStage;
                    }
                  );
                  setType({
                    ...type,
                    task_stages: updatedTaskStages,
                  });
                }}
                name="template"
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder={
                      language === "en" ? "Select Template" : "اختر القالب"
                    }
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>
                      {language === "en" ? "Select Template" : "اختر القالب"}
                    </SelectLabel>
                    {fetchedTaskTemplates?.map((option: any) => (
                      <SelectItem key={option.id} value={option.id}>
                        {option.title}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex justify-center">
              <Button
                onClick={() => {
                  setIsCreateTaskTemplate(true);
                  handleAddTaskTemplate(taskStageIndex);
                }}
              >
                {language === "en"
                  ? "Create New Task Template"
                  : "إنشاء قالب مهمة جديد"}
              </Button>
            </div>
          </Card>
        )}
      </div>
    );
  };

  const handleSave = () => {
    // console.log(type);
    if (validateForm()) {
      setFormError("");
      // setFormLoading(true);
      // console.log(type);
      // save type
      createProjectType(type)
        .then((response) => {
          // console.log("response", response);
          setFormLoading(false);
          // console.log("response", response);
          navigate("/projects");
        })
        .catch((error) => {
          console.log("Error", error);
          setSystemError(
            language === "en"
              ? "Error saving project type"
              : "خطأ في حفظ نوع المشروع"
          );
        })
        .finally(() => {
          setFormLoading(false);
        });
    }
  };

  const setInputField = (
    label: any,
    field: any,
    value: any,
    table: any,
    type = "text",
    taskStageIndex = null,
    templateIndex = null
  ) => {
    return (
      <div className="flex flex-col my-4">
        <Label className="mb-1">{label}</Label>
        <Input
          type={type}
          value={value}
          name={field}
          onChange={(e) =>
            handleChange(e, table, taskStageIndex, templateIndex)
          }
        />
      </div>
    );
  };

  const setSelectField = (
    label: any,
    field: any,
    value: any,
    options: any,
    table: any,
    taskStageIndex = null,
    templateIndex = null,
    with_add = false,
    url = ""
  ) => {
    if (!options) {
      functionToCall(field);
      return <LoadingIcon />;
    }
    return (
      <div className="flex flex-col my-6">
        <Label className="mb-1">{label}</Label>
        <div className="flex gap-2 w-full">
          <div className={`${with_add ? "w-4/5 " : "w-full"}`}>
            <Select
              onValueChange={(value) =>
                handleChange(
                  { target: { name: field, value: value } },
                  table,
                  taskStageIndex,
                  templateIndex
                )
              }
              value={value}
              name={field}
            >
              <SelectTrigger>
                <SelectValue
                  placeholder={
                    language === "en" ? `Select ${label}` : `اختر ${label}`
                  }
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>
                    {language === "en" ? `Select ${label}` : `اختر ${label}`}
                  </SelectLabel>
                  {options?.map((option: any, index: any) => (
                    <SelectItem key={index} value={option.value}>
                      {option.display_name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          {with_add && (
            <div>
              <Button
                variant="outline"
                onClick={() => functionToCall(field || "")}
              >
                <FiRefreshCw />
              </Button>
            </div>
          )}
        </div>
        {with_add && (
          <Button
            variant="link"
            className="text-sm flex justify-start w-fit"
            onClick={() => window.open(url, "_blank")}
          >
            {language === "en" ? "Add New" : "إضافة جديد"}
          </Button>
        )}
      </div>
    );
  };

  const functionToCall = (field: string) => {
    if (field === "department") {
      fetchDepartments();
    } else if (field === "stage") {
      fetchStages();
    } else if (field === "form_template") {
      fetchFormTemplates();
    }
  };

  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {stagesLoading && <LoadingScreen />}

      {systemError && (
        <ErrorCard error={systemError} onClick={() => setSystemError("")} />
      )}

      {fetchedProjectStages && fetchedDepartments && (
        <Card className="max-w-3xl mx-auto my-8 ">
          <CardHeader>
            <CardTitle className="text-xl font-bold">
              {language === "en" ? "Create Project Type" : "إنشاء نوع المشروع"}
            </CardTitle>
          </CardHeader>
          <CardContent className="text-start">
            <div className="flex flex-col gap-4">
              {setInputField(
                language === "en" ? "Title" : "العنوان",
                "title",
                type.title,
                "type"
              )}
              {setInputField(
                language === "en" ? "Description" : "الوصف",
                "description",
                type.description,
                "type"
              )}

              {setTaskForms()}

              {formError && (
                <div>
                  {formError.split("\n").map((error: any) => (
                    <div className="text-red-500 text-sm my-2 font-bold ">
                      {error}
                    </div>
                  ))}
                </div>
              )}
              <Button onClick={handleSave} disabled={formLoading}>
                {formLoading ? (
                  <LoadingIcon />
                ) : language === "en" ? (
                  "Save"
                ) : (
                  "حفظ"
                )}
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default CreateProjectType;
