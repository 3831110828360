import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ErrorCard from "@/components/error-card";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { createSite } from "@/services/site-services";

const CreateSite = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [site, setSite] = useState({
    name: "",
    description: "",
    url_location: "",
  });
  const [systemError, setSystemError] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");

  useEffect(() => {
    document.title = "Lazem GO - Create Site";
  } , []);

  const setInputField = (
    label: string,
    field: string,
    value: any,
    type?: string
  ) => {
    return (
      <div className="flex flex-col my-4 text-start">
        <Label className="mb-2">{label}</Label>
        <Input
          type={type ? type : "text"}
          value={value}
          onChange={(e) => setSite({ ...site, [field]: e.target.value })}
        />
      </div>
    );
  };

  const handelCreateSite = async () => {
    setFormLoading(true);
    if (site.name === "") {
      setFormError(
        language === "en" ? "Site Name is required" : "اسم الموقع مطلوب"
      );
      setFormLoading(false);
      return;
    } else if (
      site.url_location !== "" &&
      site.url_location.indexOf("http://") === -1 &&
      site.url_location.indexOf("https://") === -1
    ) {
      setFormError(
        language === "en"
          ? "URL Location must be a valid URL"
          : "رابط الموقع يجب ان يكون رابط صحيح"
      );
      setFormLoading(false);
      return;
    } else {
      setFormError("");
      await createSite(site)
        .then(() => {
          navigate("/sites");
        })
        .catch((error: any) => {
          setSystemError(error.message);
        })
        .finally(() => {
          setFormLoading(false);
        });
    }
  };

  return (
    <div dir={language === "ar" ? "rtl" : "ltr"}>
      {systemError !== "" && (
        <ErrorCard error={systemError} onClick={() => setSystemError("")} />
      )}
      {
        <Card className="w-full max-w-lg mx-auto mt-8">
          <CardHeader>
            <CardTitle>
              {language === "en" ? "Create Site" : "إنشاء موقع"}
            </CardTitle>
          </CardHeader>
          <CardContent>
            {setInputField(
              language === "en" ? "Site Name" : "اسم الموقع",
              "name",
              site.name
            )}
            {setInputField(
              language === "en" ? "Description" : "الوصف",
              "description",
              site.description
            )}
            {setInputField(
              language === "en" ? "URL Location" : "رابط الموقع",
              "url_location",
              site.url_location
            )}
            <p className="text-red-500 text-sm font-semibold my-4 text-start">
              {formError}
            </p>
            <Button
              onClick={handelCreateSite}
              className="mt-4"
              disabled={formLoading}
            >
              {formLoading ? (
                <LoadingIcon />
              ) : language === "en" ? (
                "Create Site"
              ) : (
                "إنشاء موقع"
              )}
            </Button>
          </CardContent>
        </Card>
      }
    </div>
  );
};

export default CreateSite;
