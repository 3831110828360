import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ErrorCard from "@/components/error-card";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { getDepartment, updateDepartment } from "@/services/project-service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
} from "@/components/ui/select";
import { roles } from "@/services/user-service";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { MdDeleteForever } from "react-icons/md";

const EditDepartment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [updatedFields, setUpdatedFields] = useState<any>();
  const [systemError, setSystemError] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const queryClient = useQueryClient();
  const [isDepartmentEdit, setIsDepartmentEdit] = useState(false);
  const [addedRoles, setAddedRoles] = useState<any>({
    title: "",
    position: "",
    description: "",
    department: id,
  });
  const [postionChoices, setPositionChoices] = useState<any>([
    "manager",
    "staff",
  ]);
  const [isRoleLoading, setIsRoleLoading] = useState(false);
  const [isAddingRole, setIsAddingRole] = useState(false);

  useEffect(() => {
    document.title = "Lazem GO - Edit Department";
  } , []);

  const {
    data: department,
    isLoading: departmentLoading,
    isFetching: departmentFetching,
    refetch: departmentRefetching,
  } = useQuery({
    queryKey: ["edit department"],
    queryFn: () => {
      return getDepartment(id).then((response) => {
        // console.log(response.data);
        return response;
      });
    },
    refetchOnWindowFocus: false,
  });

  const checkForm = () => {
    if (department?.data.title === "") {
      setFormError(language === "en" ? "Title is required" : "العنوان مطلوب");
      return false;
    } else if (!updatedFields) {
      setFormError(
        language === "en" ? "No changes detected" : "لا توجد تغييرات"
      );
      return false;
    }
    return true;
  };

  const handleUpdate = async () => {
    if (checkForm()) {
      setFormLoading(true);
      await updateDepartment(id, updatedFields)
        .then(() => {
          navigate("/projects/settings");
        })
        .catch((error) => {
          setSystemError(error.message);
        });
      setFormLoading(false);
    }
  };

  const checkRoleForm = () => {
    if (addedRoles.title === "") {
      setFormError(language === "en" ? "Title is required" : "العنوان مطلوب");
      return false;
    } else if (addedRoles.position === "") {
      setFormError(language === "en" ? "Position is required" : "المنصب مطلوب");
      return false;
    }
    return true;
  };

  const deleteRole = (id: string) => {
    roles
      .update(id, { is_active: false })
      .then(() => {
        departmentRefetching();
      })
      .catch((error) => {
        setSystemError(error.message);
      });
  };

  const handleAddRole = () => {
    // console.log(addedRoles);
    if (checkRoleForm()) {
      setIsRoleLoading(true);
      roles
        .create(addedRoles)
        .then(() => {
          departmentRefetching();
          setAddedRoles({
            title: "",
            position: "",
            description: "",
            department: id,
          });
        })
        .catch((error) => {
          setSystemError(error.message);
        })
        .finally(() => {
          setIsRoleLoading(false);
        });
    }
  };

  const departmentRolesComponent = () => {
    return (
      <>
        {department?.data.roles?.map((role: any) => (
          <div
            key={role.id}
            className="flex justify-between items-center border-b border-gray-200 py-2"
          >
            {/* delete diolog */}
            <Dialog>
              <DialogTrigger asChild>
                <MdDeleteForever className="text-red-500 cursor-pointer" />
              </DialogTrigger>
              <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle className="text-center">
                    {language === "en" ? (
                      <>
                        Are you sure you want to delete
                        <br />
                        <br />
                        {role.title}?
                      </>
                    ) : (
                      <>
                        هل أنت متأكد أنك تريد حذف
                        <br />
                        <br />
                        {role.title}؟
                      </>
                    )}
                  </DialogTitle>
                </DialogHeader>

                <DialogFooter>
                  <Button
                    type="submit"
                    variant="destructive"
                    className="m-auto"
                    disabled={formLoading || departmentLoading}
                    onClick={() => {
                      deleteRole(role.id);
                    }}
                  >
                    {language === "en" ? "Delete" : "حذف"}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
            <p>{role.description}</p>
            <p>{role.title}</p>
          </div>
        ))}
        {setRoleComponent()}
      </>
    );
  };

  const setRoleComponent = () => {
    return (
      <div className="text-start">
        {isAddingRole ? (
          <Button
            onClick={() => {
              setIsAddingRole(false);
            }}
            variant={"destructive"}
            className="w-fit"
          >
            {language === "en" ? "Cancel" : "إلغاء"}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setIsAddingRole(true);
            }}
            variant={"secondary"}
            className="w-fit"
          >
            {language === "en" ? "Add Role" : "إضافة دور"}
          </Button>
        )}
        {isAddingRole && (
          <>
            <div className="my-4">
              <Label className="mb-4">
                {language === "en" ? "Role title" : "المسمى الوظيفي"}
              </Label>
              <Input
                value={addedRoles.title}
                onChange={(e) => {
                  setAddedRoles({
                    ...addedRoles,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mb-4">
              <Label className="mb-4">
                {language === "en" ? "Description" : "الوصف"}
              </Label>
              <Input
                value={addedRoles.description}
                onChange={(e) => {
                  setAddedRoles({
                    ...addedRoles,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mb-4">
              <Label className="mb-4">
                {language === "en" ? "Position" : "المنصب"}
              </Label>
              <Select
                value={addedRoles.position}
                onValueChange={(value) => {
                  setAddedRoles({
                    ...addedRoles,
                    position: value,
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue>{addedRoles.position}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>
                      {language === "en" ? "Position" : "المنصب"}
                    </SelectLabel>
                    {postionChoices.map((position: any) => (
                      <SelectItem key={position} value={position}>
                        {position}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="flex justify-end">
              <Button
                onClick={handleAddRole}
                className="w-full"
                disabled={isRoleLoading}
              >
                {isRoleLoading ? (
                  <>
                    <LoadingIcon />
                  </>
                ) : language === "en" ? (
                  "Add Role"
                ) : (
                  "إضافة دور"
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div
      className="flex flex-col items-center justify-center h-full"
      dir={language === "en" ? "ltr" : "rtl"}
    >
      {(departmentLoading || departmentFetching) && systemError !== "" && <LoadingScreen />}
      {systemError !== "" && (
        <ErrorCard error={systemError} onClick={() => setSystemError("")} />
      )}
      {
        <>
          <Card className="w-full md:w-1/2">
            <CardHeader>
              <CardTitle>
                {language === "en" ? "Edit Department" : "تعديل القسم"}
              </CardTitle>
              {isDepartmentEdit ? (
                <Button
                  onClick={() => {
                    setIsDepartmentEdit(false);
                  }}
                  variant={"destructive"}
                  className="w-fit"
                >
                  {language === "en" ? "Cancel" : "إلغاء"}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setIsDepartmentEdit(true);
                  }}
                  variant={"secondary"}
                  className="w-fit"
                >
                  {language === "en" ? "Edit" : "تعديل"}
                </Button>
              )}
            </CardHeader>
            <CardContent className="text-start">
              <div className="">
                <div className="mb-4">
                  <Label className="mb-4">
                    {language === "en" ? "Title" : "العنوان"}
                  </Label>
                  <Input
                    value={department?.data.title}
                    onChange={(e) => {
                      queryClient.setQueryData(
                        ["edit department"],
                        (oldData: any) => {
                          return {
                            ...oldData,
                            data: {
                              ...oldData.data,
                              title: e.target.value,
                            },
                          };
                        }
                      );
                      setUpdatedFields({
                        ...department,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mb-4">
                  <Label className="mb-4">
                    {language === "en" ? "Description" : "الوصف"}
                  </Label>
                  <Input
                    value={department?.data.description}
                    onChange={(e) => {
                      queryClient.setQueryData(
                        ["edit department"],
                        (oldData: any) => {
                          return {
                            ...oldData,
                            data: {
                              ...oldData.data,
                              description: e.target.value,
                            },
                          };
                        }
                      );
                      setUpdatedFields({
                        ...department,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
                {isDepartmentEdit && (
                  <>
                    {formError !== "" ? (
                      <div className="mb-4 text-red-500">{formError}</div>
                    ) : null}
                    <div className="flex justify-end">
                      <Button
                        onClick={handleUpdate}
                        disabled={formLoading}
                        className="w-full "
                      >
                        {formLoading ? (
                          <>
                            <LoadingIcon />
                          </>
                        ) : language === "en" ? (
                          "Update"
                        ) : (
                          "تحديث"
                        )}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </CardContent>
            <Card className="w-full border-none">
              <CardHeader>
                <CardTitle>{language === "en" ? "Roles" : "الأدوار"}</CardTitle>
              </CardHeader>
              <CardContent>{departmentRolesComponent()}</CardContent>
            </Card>
          </Card>
        </>
      }
    </div>
  );
};

export default EditDepartment;
