import { useEffect, useState } from "react";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { Card } from "./ui/card";
import { ModeToggle } from "./mode-toggle";
import { useLanguage } from "./context/LanguageContext";
import { useAuth } from "./context/AuthContext";
import { useNavigate } from "react-router-dom";
import availableRoutes from "@/services/pages-route-btn-service";
import { MdLanguage } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { getMyUser, users } from "@/services/user-service";
import { useQuery } from "@tanstack/react-query";
import ErrorCard from "./error-card";
import LoadingIcon from "./loading/loading-icon";
import lazemGoLogo from "../assets/lazem-GO-logo.webp";

export default function SideNavigation() {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const { language, changeLanguage } = useLanguage();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [DashboardBtns] = useState(availableRoutes(20));
  const [error, setError] = useState("");

  const fetchUser = async () => {
    return await users.simple_me()
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        users.verifyToken().catch((err:any) => {
          if(err?.response?.status === 401){
            logout();
          }
          else{
            throw err;
          }
        });
        throw error;
        setError(error.message);
      });
  };

  const { isLoading: userLoading, data:user } = useQuery({
    queryKey: ["side bar users"],
    queryFn: fetchUser,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <Button
        title="Side navigation"
        variant={"secondary"}
        className={`visible fixed left-6 top-6 z-40 order-10 block h-10 w-10 self-center rounded opacity-100 lg:hidden ${
          isSideNavOpen
            ? "visible opacity-100 [&_span:nth-child(1)]:w-6 [&_span:nth-child(1)]:translate-y-0 [&_span:nth-child(1)]:rotate-45 [&_span:nth-child(3)]:w-0 [&_span:nth-child(2)]:-rotate-45 "
            : ""
        }`}
        aria-haspopup="menu"
        aria-label="Side navigation"
        aria-expanded={isSideNavOpen ? true : false}
        aria-controls="nav-menu-4"
        onClick={() => setIsSideNavOpen(!isSideNavOpen)}
      >
        <div className="absolute top-1/2 left-1/2 w-6 -translate-x-1/2 -translate-y-1/2 transform">
          <span
            aria-hidden="true"
            className="absolute block h-0.5 w-9/12 -translate-y-2 transform rounded-full bg-cyan-700 transition-all duration-300"
          ></span>
          <span
            aria-hidden="true"
            className="absolute block h-0.5 w-6 transform rounded-full bg-cyan-900 transition duration-300"
          ></span>
          <span
            aria-hidden="true"
            className="absolute block h-0.5 w-1/2 origin-top-left translate-y-2 transform rounded-full bg-cyan-900 transition-all duration-300"
          ></span>
        </div>
      </Button>

      {/*  <!-- Side Navigation --> */}
      <Card
        id="nav-menu-4"
        aria-label="Side navigation"
        className={`fixed top-0 bottom-0 left-0 z-40 flex w-60 flex-col border-r transition-transform lg:translate-x-0  ${
          isSideNavOpen ? "translate-x-0" : " -translate-x-full"
        }`}
      >
        <>
          <div className="flex items-center justify-center px-6 pt-2">
            <img
              src={lazemGoLogo}
              alt="logo"
              className=""
            />
          </div>
          <div className="flex flex-col items-center gap-4 border-b border-slate-200 p-2">
            {userLoading && error === "" ? (
              <div className="flex items-center justify-center h-screen">
                <LoadingIcon />
              </div>
            ) : error !== "" ? (
              language === "en" ? 'Error loading user' : 'خطأ في تحميل المستخدم'
            ) : (
              user && (
                <div
                  className="flex min-h-[2rem] w-full min-w-0 flex-col items-start justify-center gap-0 text-center cursor-pointer"
                  onClick={() => {
                    setIsSideNavOpen(false);
                    navigate("/profile");
                  }}
                >
                  <Label className="w-full truncate text-base cursor-pointer">
                    {user && user.full_name}
                  </Label>
                  <Label className="w-full truncate text-sm cursor-pointer">
                    {user && user.email}
                  </Label>
                </div>
              )
            )}
          </div>
          <nav
            aria-label="side navigation"
            className="flex-1 divide-y divide-slate-100 overflow-auto"
          >
            <div>
              <ul className="flex flex-1 flex-col gap-1 py-3">
                {DashboardBtns.map((btn, index) => (
                  <div key={index}>
                    {btn.isActive && (
                      <li className="px-3">
                        <div
                          className="flex items-center gap-3 rounded p-3 transition-colors focus:bg-stone-50 aria-[current=page]:bg-stone-50 cursor-pointer"
                          onClick={() => {
                            setIsSideNavOpen(false);
                            navigate(btn.route);
                          }}
                        >
                          <div className="flex items-center self-center ">
                            {btn.icon}
                          </div>
                          <div className="flex w-full flex-1 flex-col items-start justify-center gap-0 overflow-hidden truncate text-sm">
                            {language === "en" ? btn.title_en : btn.title_ar}
                          </div>
                        </div>
                      </li>
                    )}
                  </div>
                ))}
              </ul>
            </div>
            <div className="pt-3">
              <ul className="flex flex-1 flex-col gap-1 py-3">
                <li
                  className="px-3 cursor-pointer"
                  onClick={() => {
                    setIsSideNavOpen(false);
                    changeLanguage(language === "en" ? "ar" : "en");
                  }}
                >
                  <div className="flex items-center gap-3 rounded p-3 transition-colors focus:bg-stone-50 aria-[current=page]:bg-stone-50 ">
                    <div className="flex items-center self-center ">
                      <MdLanguage size={20} />
                    </div>
                    <div className="flex w-full flex-1 flex-col items-start justify-center gap-0 overflow-hidden truncate text-sm">
                      {language === "en" ? "العربية" : "English"}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
          <div className="">
            v{process.env.VERSION || "0.0.0"}
          </div>
          <footer className="border-t border-slate-200 p-3 flex justify-between gap-4">
            <div
              className="flex items-center gap-3 rounded p-3  transition-colors hover:text-stone-500 cursor-pointer"
              onClick={logout}
            >
              <div className="flex items-center self-center ">
                <CiLogout size={20} />
              </div>
              <div className="flex w-full flex-1 flex-col items-start justify-center gap-0 overflow-hidden truncate text-sm font-medium">
                {language === "en" ? "Logout" : "تسجيل الخروج"}
              </div>
            </div>

            <div className="flex items-center justify-center gap-2 ">
              <ModeToggle />
            </div>
          </footer>
        </>
      </Card>

      {/*  <!-- Backdrop --> */}
      <div
        className={`fixed top-0 bottom-0 left-0 right-0 z-30 bg-slate-900/20 transition-colors sm:hidden ${
          isSideNavOpen ? "block" : "hidden"
        }`}
        onClick={() => setIsSideNavOpen(false)}
      ></div>
    </>
  );
}
