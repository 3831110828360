import { LuLayoutDashboard } from "react-icons/lu"; //dashboard
import { PiAmbulanceBold } from "react-icons/pi"; //fleet
import { PiUsersThreeBold } from "react-icons/pi"; //users
import { SiReacthookform } from "react-icons/si"; //form
import { MdLocationCity } from "react-icons/md"; //site
import { FaTasks } from "react-icons/fa"; //tasks
import { LiaProjectDiagramSolid } from "react-icons/lia"; //project
import { CiSettings } from "react-icons/ci"; //settings
import { SiAuth0 } from "react-icons/si"; //permission
import { useAuth } from "@/components/context/AuthContext";
import { MdSupportAgent } from "react-icons/md";

const availableRoutes =(iconSize:any) => {
    const { checkPermission } = useAuth();
    return [
        {
            title_en: 'Home Page',
            title_ar: 'الصفحة الرئيسية',
            route: '/',
            icon: <LuLayoutDashboard size={iconSize || null}/>,
            isActive: true
        },
        {
            title_en: 'Projects Dashboard',
            title_ar: 'لوحة المشاريع',
            route: '/projects',
            icon: <LiaProjectDiagramSolid size={iconSize || null}/>,
            isActive: checkPermission('view_projectsdashboard') 
        },
        {
            title_en: 'Projects Settings',
            title_ar: 'إعدادات المشاريع',
            route: '/projects/settings',
            icon: <CiSettings size={iconSize || null}/>,
            isActive: checkPermission('changeprojects')
        },
        {
            title_en: 'Tasks Dashboard',
            title_ar: 'لوحة المهام',
            route: '/tasks',
            icon: <FaTasks size={iconSize || null}/>,
            isActive: checkPermission('view_tasksdashboard')
        },
        {
            title_en: 'Fleet Dashboard',
            title_ar: 'لوحة الأسطول',
            route: '/fleet',
            icon: <PiAmbulanceBold size={iconSize || null}/>,
            isActive: checkPermission('view_vehiclesdashboard')
        },
        {
            title_en: 'Sites Management',
            title_ar: 'إدارة المواقع',
            route: '/sites',
            icon: <MdLocationCity size={iconSize || null}/>,
            isActive: checkPermission('view_sitesdashboard')
        },
        {
            title_en: 'Users Management',
            title_ar: 'إدارة المستخدمين',
            route: '/users-management',
            icon: <PiUsersThreeBold size={iconSize || null}/>,
            isActive: checkPermission('view_usersdashboard')
        },
        {
            title_en: 'Forms Dashboard',
            title_ar: 'لوحة النماذج',
            route: '/forms',
            icon: <SiReacthookform size={iconSize || null}/>,
            isActive: checkPermission('view_formsdashboard')
        },
        {
            title_en: 'Permissions Management',
            title_ar: 'إدارة الصلاحيات',
            route: '/permission-group',
            icon: <SiAuth0 size={iconSize || null}/>,
            isActive: checkPermission('view_permissionsdashboard')
        },
        {
            title_en: 'Support',
            title_ar: 'الدعم',
            route: '/support',
            icon: <MdSupportAgent size={iconSize || null}/>,
            isActive: false
        }
    ];
};

export default availableRoutes;