import { useLanguage } from "@/components/context/LanguageContext";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SelectSupportRequest = () =>{
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [supportlist, setSupportList] = useState([
        {
            title_en : "On Boarding",
            title_ar : "بدء الخدمة",
            route : "/support/onboarding"
        },
        {
            title_en : "Off Boarding",
            title_ar : "إنهاء الخدمة",
            route : "/support/offboarding"
        },
    ])

    return (
        <>
            <div>
                <h1 className="text-3xl font-bold text-center m-auto mt-10">
                    {language === 'en' ? "Select Support Request" : "اختر طلب الدعم"}
                </h1>
            </div>
            <div
                className="flex flex-wrap mx-auto mt-10 gap-6 p-4 justify-content-center"
            >
                {supportlist.map((service, index) => (  
                    <div
                        key={index}
                        className="m-auto"
                    >
                        {
                            <Card
                                className="m-3 w-64 h-64 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={() => navigate(service.route)}
                            >
                                <CardHeader
                                    className="h-1/3 w-full flex justify-content-center m-auto"
                                >
                                    <CardTitle
                                        className="text-2xl font-bold flex justify-content-center m-auto"
                                    >
                                        {language === 'en' ? service.title_en : service.title_ar}
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        }                
                    </div> 
                ))    
                }
            </div>
        </>
    )
}

export default SelectSupportRequest;