import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { getAllUsers, users } from "@/services/user-service";
import LoadingScreen from "@/components/loading/loading-screen";
import ErrorCard from "@/components/error-card";
import { useEffect, useState } from "react";

const UsersManagement = () => {
    const navigate = useNavigate();
    const { language } = useLanguage();
    const [systemError, setSystemError] = useState<any>('');

    useEffect(() => {
        document.title = "Lazem GO - Users Management";
    } , []);
    
    const { data:usersData, isLoading:usersLoading, isError, error } = useQuery({
        queryKey: ['users management'],
        queryFn:()=> {
            return users.simple().then((response) => {
                console.log(response);
                return response;
            }).catch((error) => {
                setSystemError(error.message);
            });
        },
        refetchOnWindowFocus: false
    });

  return (
    <div
        dir={language === 'en' ? 'ltr' : 'rtl'}
        className="p-4"
    >
        {usersLoading && systemError !== "" && <LoadingScreen />}
        {systemError && <ErrorCard error={systemError} onClick={() => {setSystemError('')}} />}
        {
            
            <div>
                <div
                    className="my-6"
                >
                    <h1
                        className="text-2xl font-bold"
                    >
                        {language === 'en' ? 'Users Management' : 'إدارة المستخدمين'}
                    </h1>
                </div>
                <div
                    className="flex flex-wrap gap-4 justify-center"
                >
                    {usersData && usersData.data.map((user:any) => {
                            return (
                                <Card 
                                    key={user.id}
                                    className="w-64"
                                >                      
                                    <CardHeader
                                        className="min-h-32"
                                    >
                                        <CardTitle
                                            className="text-lg font-bold capitalize"
                                        >
                                            {user.full_name}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p>{user.email}</p>
                                    </CardContent>
                                    <CardFooter>
                                        <Button onClick={() => navigate(`/users-management/id/${user.id}`)}>{language === 'en' ? 'View' : 'عرض'}</Button>
                                    </CardFooter>
                                </Card>
                            )

                        })
                    }
                </div>
            </div>
        }
    </div>
  );
};

export default UsersManagement;